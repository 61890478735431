import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import slugify from 'slugify'
import parse from 'html-react-parser'

import Layout from '../../layout'
import Cta from '../../layout/components/main/components/cta'
import * as styles from './careers.module.scss'

export default ({ pageContext: { title }, location }) => {
  const [career, setCareer] = useState(undefined)
  const query = useStaticQuery(graphql`
  {
    allMarkdownRemark {
      nodes {
        frontmatter {
          content
          details
          requirements
          summaryLabel
          title
        }
        html
      }
    }
    allSettingsJson {
      nodes {
        careerCta {
          image
          label
          sharedTextButton {
            buttonType
            label
          }
          imageX
          imageY
        }
      }
    }
  }
  `)
  const { allMarkdownRemark: { nodes } } = query
  useEffect(() => {
    setCareer(nodes.find(node => node.frontmatter.title === title))
  }, [query])

  return career
    ? (
      <Layout location={location} title={title} description={null} image={null} slug={slugify(title).toLowerCase()} components={[]}>
        <main className={styles.main}>
          <div className={styles.grid}>
            <div className={styles.titleContainer}>
              <p className={styles.title}>Position title:</p>
              <h1 className={styles.position}>{career.frontmatter.title}</h1>
            </div>
            <div className={styles.summaryContainer}>
              <p className={styles.title}>At a glance:</p>
              <div className={styles.titleLabel}>
                <h2 className={styles.summaryLabel}>{career.frontmatter.summaryLabel}</h2>
                <p className={styles.content}>{career.frontmatter.content}</p>
              </div>
            </div>
            <div className={styles.detailsContainer}>
              <p className={styles.title}>Position details:</p>
              {career.frontmatter.details && parse(career.frontmatter.details)}
            </div>
            <div className={styles.requirementsContainer}>
              <p className={styles.title}>Requirements:</p>
              {career.frontmatter.requirements && parse(career.frontmatter.requirements)}
            </div>
          </div>
        </main>
        <Cta component={query.allSettingsJson.nodes[0].careerCta} />
      </Layout>
      )
    : <section />
}
