// extracted by mini-css-extract-plugin
export var main = "careers-module--main--2gfOx";
export var grid = "careers-module--grid--2zsNo";
export var titleContainer = "careers-module--title-container--27Kcl";
export var title = "careers-module--title--2358d";
export var summaryContainer = "careers-module--summary-container--G_jIF";
export var detailsContainer = "careers-module--details-container--2wwCZ";
export var requirementsContainer = "careers-module--requirements-container--3yzQK";
export var position = "careers-module--position--2q7fN";
export var titleLabel = "careers-module--title-label--1MdWP";
export var summaryLabel = "careers-module--summary-label--2dmJW";
export var content = "careers-module--content--3nWH6";